import './App.css';
import Banner from './components/Banner'
import Story from './components/Story';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="App">
      <Banner></Banner>
      <Story></Story>
      <Analytics />
    </div>
  );
}

export default App;
