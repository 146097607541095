import React, { useRef, useEffect, useState } from "react";
import Raven from "./Raven";
import Owl from "./Owl.js"
import { GridLoader } from 'react-spinners';
import axios from 'axios';
import axiosRetry from "axios-retry";

// Import your assets
import bannerLogo from "../assets/YoutubeCreepyFace.png";
import bannerHead from "../assets/landingPageHead2.png";
import story from "../assets/tellastoryLine.png";
import subscribe from "../assets/subscribe.png";
import storySound from "../assets/ScaryStoryTell.wav";
import ravenImg from "../assets/Raven.png";
import owlImg from "../assets/OwlsSprite.png"
import bannerBackGround from "../assets/landingPageBackground.mp4";
import mobileBannerBackground from "../assets/MobileLandingPage.mp4";



export default function Banner() {
  let lastPlayTime = useRef(0);
  const canvasRef = useRef(null);

  const ravens = useRef([]); // Use ref to keep track of the ravens
  const [ravensCreated, setRavensCreated] = useState(false);

  const owls = useRef([]); // Use ref to keep track of the Owls
  const [owlsCreated, setOwlsCreated] = useState(false)

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [mouseMoved, setMouseMoved] = useState(false);
  const [isAssetsLoaded, setAssetsLoaded] = useState(false);

  const [loadedVideoLarge, setLoadedVideoLarge] = useState(false);
  const [loadedVideoSmall, setLoadedVideoSmall] = useState(false);
  // Load all assets and set isAssetsLoaded to true when done

  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const optionsBackUp = {
      method: 'GET',
      url: process.env.REACT_APP_BANNER_CREEPY_ECHOES
    };

    axiosRetry(axios, {
      retries: 10,
      retryDelay: (retryCount) => {
        // For the first 5 retries, the delay is a constant 0.5s.
        // For the next 5 retries, the delay increases by 1s per attempt, starting at 1s.
        // For the final 5 retries, the delay is a constant 5s.
        if (retryCount <= 5) {
          return 500;
        } else if (retryCount <= 10) {
          return (retryCount - 5) * 1000;
        } else {
          return 5000;
        }
      }
    });

    axios.request(optionsBackUp).then(function (response) {
    }).catch(function (errorBackup) {
      console.error(errorBackup)
    })
  }, []);

  useEffect(() => {
    const imgAssets = [bannerLogo, bannerHead, story, subscribe, ravenImg];

    const imgPromises = imgAssets.map((asset) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = asset;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all([...imgPromises])
      .then(() => {
        setTimeout(() => {
          setAssetsLoaded(true);
        }, 600); // delay
      })
      .catch((error) => console.error("Failed to load all assets:", error));
  }, []);


  function play() {
    let now = Date.now();
    if (now - lastPlayTime.current > 2000) {
      new Audio(storySound).play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
      lastPlayTime.current = now;
    } else {
      console.error("Only One Audio can play at a time");
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      const context = canvas.getContext('2d');
      // Rest of your code

      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      console.log(ravens)

      if (!ravensCreated) {
        const sprite = new Image();
        sprite.src = ravenImg;

        sprite.onload = () => {
          // Initialize some ravens
          let scale;
          if (window.innerWidth > 900) {
            scale = 0.4;
          } else {
            scale = 0.20;
          }
          for (let i = 0; i < 20; i++) {
            const raven = new Raven(context, sprite, i * -200, (Math.random() * canvas.height * 0.4) + canvas.height * 0.2, scale);
            ravens.current.push(raven);
          }

          setRavensCreated(true); // Set the state to true after ravens are created
        };

        sprite.onerror = () => {
          console.log('Error loading sprite');
        };

      }

      if (!owlsCreated) {
        const sprite = new Image();
        sprite.src = owlImg;

        sprite.onload = () => {
          let scale;
          if (window.innerWidth > 900) {
            scale = 1.5;
          } else {
            scale = 1;
          }

          for (let i = 0; i < 4; i++) {
            const owl = new Owl(context, sprite, (i * 200) + canvas.width, (Math.random() < 0.99 ? Math.random() * canvas.height * 0.2 : canvas.height * 0.4), scale);
            owls.current.push(owl)
          }
          setOwlsCreated(true) // Makes sure owls are only created one the page loads and not again

        }
        sprite.onerror = () => {
          console.log('Error loading sprite');
        };
      }

      if (ravensCreated && owlsCreated && !animationStarted) {
        console.log('Animating');

        setAnimationStarted(true);
        const animate = () => {
          context.clearRect(0, 0, canvas.width, canvas.height); // clear canvas once per frame
          ravens.current.forEach((raven) => {
            raven.update();
            raven.draw();
            // Reset position if off screen
            if (raven.offScreen(canvas.width)) {
              raven.x = -raven.sprite.width / raven.numberOfFrames;
              raven.y = (Math.random() * canvas.height * 0.4) + canvas.height * 0.2;
              raven.speed = Math.random() * 5 + 2;
              raven.flapSpeed = Math.random() * 0.2 + 0.1;
            }
          });
          owls.current.forEach((owl) => {
            owl.update();
            owl.draw();
            // Reset position if off screen
            if (owl.offScreen(canvas.width)) {
              console.log("owl is offcreen reseting")
              owl.x = (owl.sprite.width / owl.numberOfFrames) + canvas.width;
              owl.y = (Math.random() < 0.85 ? Math.random() * canvas.height * 0.2 : canvas.height * 0.4);
              owl.speed = (-1 * (Math.random() * 5 + 2));
              owl.flapSpeed = Math.random() * 0.2 + 0.1;
            }
          });
          requestAnimationFrame(animate); // loop animation
        };
        animate(); // start animation
      }


    }

  }, [ravensCreated, isAssetsLoaded, loadedVideoLarge, loadedVideoSmall, owlsCreated, animationStarted]);

  useEffect(() => {
    const handleResize = () => {
      let scale;
      if (window.innerWidth > 900) {
        scale = 0.5;
      } else {
        scale = 0.25;
      }
      ravens.current.forEach((raven) => {
        raven.scale = scale;
      });
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseMove = (event) => {
    const x = (event.clientX - window.innerWidth / 2) * 100 / window.innerWidth;
    const y = (event.clientY - window.innerHeight / 2) * 100 / window.innerHeight;
    setPosition({ x, y });
    setMouseMoved(true);

  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const eyeLeftClass = mouseMoved ? 'eye eye-left stop-animation' : 'eye eye-left';
  const eyeRightClass = mouseMoved ? 'eye eye-right stop-animation' : 'eye eye-right';


  const handleLoadedVideoLargeData = () => {
    setLoadedVideoLarge(true);
  };

  const handleLoadedVideoSmallData = () => {
    setLoadedVideoSmall(true);
  };

  return (
    <section className="banner">
      <video
        autoPlay
        muted
        loop
        id="myVideoLarge"
        playsInline
        preload="auto"
        onLoadedData={handleLoadedVideoLargeData}
      >
        <source src={bannerBackGround} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <video
        autoPlay
        muted
        loop
        id="myVideoSmall"
        playsInline
        preload="auto"
        onLoadedData={handleLoadedVideoSmallData}
      >
        <source src={mobileBannerBackground} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      {(!isAssetsLoaded && (!loadedVideoLarge || !loadedVideoSmall)) ? (
        <>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'black'

          }}>
            <GridLoader
              color="#00BFFF"
              size={30}
              loading={true}
            />
          </div>
        </>) : (<>
          <canvas ref={canvasRef} className="bird-canvas"></canvas>
          <div className="banner-logo">
            <a href="https://www.youtube.com/channel/UCCZqy3WJeKRW0WmgnjLBriQ?sub_confirmation=1">
              <img src={bannerLogo} alt="logo"></img>
            </a>
          </div>
          <div className="banner-head">
            <div className={eyeLeftClass}
              style={mouseMoved ? {
                transform: `translate(${position.x * 0.15}px, ${position.y * 0.15}px)`
              } : {}}></div>
            <div className={eyeRightClass} style={mouseMoved ? {
              transform: `translate(${position.x * 0.15}px, ${position.y * 0.15}px)`
            } : {}}></div>
            <img src={bannerHead} alt="logo">
            </img>
          </div>
          <div className="banner-bottom">
            <div className="banner-bottom-left">
              <a id="story-audio" href="#story" onClick={play}>
                <img src={story} alt="tell a spooky story"></img>
              </a>
            </div>
            <div className="banner-bottom-right">
              <a href="https://www.youtube.com/channel/UCCZqy3WJeKRW0WmgnjLBriQ?sub_confirmation=1">
                <img src={subscribe} alt="subscribe"></img>
              </a>
            </div>
          </div>
          <div className="shadow"></div>

        </>)}


    </section>
  );
}

//Deploymnet Github