
import React, { useState } from 'react';
import axios from 'axios';
import storyBackground from '../assets/StoryBackground.mp4'
import { GridLoader } from 'react-spinners';


export default function Story() {

    const [name, setName] = useState('');
    const [story, setStory] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(true);
    const handleLoadedVideoData = () => {
        setLoadingVideo(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!name || !story) {
            setError('Both fields are required');
            return;
        }

        setLoading(true);

        try {
            await axios.post(process.env.REACT_APP_SECRET_STORY, { name, story });

            setName('');
            setStory('');
            setLoading(false);
            setSubmitted(true);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    };


    return (
        <section id="story">
            {loadingVideo ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'black'
                }}>
                    <GridLoader color={"#00BFFF"} loading={true} size={30} />
                </div>
            ) : (<>
                <div className={`form-container ${submitted ? 'minimize' : ''}`}>
                    {!submitted ? (
                        <form onSubmit={handleSubmit}>
                            <label>
                                Name or Alias:
                                <input
                                    type="text"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Story:
                                <textarea
                                    value={story}
                                    onChange={e => setStory(e.target.value)}
                                    required
                                />
                            </label>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <button type="submit">Submit Story</button>
                            )}
                            {error && <div>Error: {error}</div>}
                        </form>
                    ) : (
                        <div className="submission-success">
                            <h1>Thank you!</h1>
                            <button disabled>Your Story Successfully Submitted!</button>
                        </div>
                    )}
                </div> </>)}


            <video
                autoPlay
                muted
                loop
                id="myVideoTwo"
                playsInline
                preload="auto"
                onLoadedData={handleLoadedVideoData}
            >
                <source src={storyBackground} type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
        </section>
    )
}