class Owl {
    constructor(context, sprite, x, y, scale = 1) {
        this.context = context;
        this.sprite = sprite;
        this.x = x;
        this.y = y;
        this.scale = scale;

        this.speed = -1 * (Math.random() * 5 + 2);
        this.flapSpeed = Math.random() * 0.2 + 0.1;
        this.frameIndex = 0;
        this.tickCount = 0;
        this.numberOfFrames = 6;
    }

    update() {
        this.x += this.speed;
        this.tickCount += this.flapSpeed;
        if (this.tickCount >= 1) {
            this.tickCount -= 1;
            this.frameIndex = (this.frameIndex + 1) % this.numberOfFrames;
        }

    }

    draw() {
        const frameWidth = this.sprite.width / this.numberOfFrames;
        this.context.drawImage(
            this.sprite,
            this.frameIndex * frameWidth, // source x
            0, // source y
            frameWidth, // source width
            this.sprite.height, // source height
            this.x, // target x
            this.y, // target y
            frameWidth * this.scale, // target width
            this.sprite.height * this.scale // target height
        );
    }

    offScreen() {
        return this.x + this.sprite.width / this.numberOfFrames < 0;
    }


}

export default Owl;
